;(function($){$.lazy("ajax",function(element,response){ajaxRequest(this,element,response,element.attr("data-method"));});$.lazy("get",function(element,response){ajaxRequest(this,element,response,"get");});$.lazy("post",function(element,response){ajaxRequest(this,element,response,"post");});function ajaxRequest(instance,element,response,method){$.ajax({url:element.attr("data-src"),type:method||"get",dataType:element.attr("data-type")||"html",success:function(content){element.html(content);response(true);if(instance.config("removeAttribute"))
element.removeAttr("data-src data-method data-type");},error:function(){response(false);}});}})(window.jQuery||window.Zepto);;(function($){$.lazy(["av","audio","video"],["audio","video"],function(element,response){var elementTagName=element[0].tagName.toLowerCase();if(elementTagName==="audio"||elementTagName==="video"){var srcAttr="data-src",sources=element.find(srcAttr),tracks=element.find("data-track"),sourcesInError=0,onError=function(){if(++sourcesInError===sources.length)
response(false);},handleSource=function(){var source=$(this),type=source[0].tagName.toLowerCase(),attributes=source.prop("attributes"),target=$(type===srcAttr?"<source>":"<track>");if(type===srcAttr)
target.one("error",onError);$.each(attributes,function(index,attribute){target.attr(attribute.name,attribute.value);});source.replaceWith(target);};element.one("loadedmetadata",function(){response(true);}).off("load error").attr("poster",element.attr("data-poster"));if(sources.length)
sources.each(handleSource);else if(element.attr(srcAttr)){$.each(element.attr(srcAttr).split(","),function(index,value){var parts=value.split("|");element.append($("<source>").one("error",onError).attr({src:parts[0].trim(),type:parts[1].trim()}));});if(this.config("removeAttribute"))
element.removeAttr(srcAttr);}
else{response(false);}
if(tracks.length)
tracks.each(handleSource);}
else{response(false);}});})(window.jQuery||window.Zepto);;(function($){$.lazy(["frame","iframe"],"iframe",function(element,response){var instance=this;if(element[0].tagName.toLowerCase()==="iframe"){var srcAttr="data-src",errorDetectAttr="data-error-detect",errorDetect=element.attr(errorDetectAttr);if(errorDetect!=="true"&&errorDetect!=="1"){element.attr("src",element.attr(srcAttr));if(instance.config("removeAttribute"))
element.removeAttr(srcAttr+" "+errorDetectAttr);}
else{$.ajax({url:element.attr(srcAttr),dataType:"html",crossDomain:true,xhrFields:{withCredentials:true},success:function(content){element.html(content).attr("src",element.attr(srcAttr));if(instance.config("removeAttribute"))
element.removeAttr(srcAttr+" "+errorDetectAttr);},error:function(){response(false);}});}}
else{response(false);}});})(window.jQuery||window.Zepto);;(function($){$.lazy("noop",function(){});$.lazy("noop-success",function(element,response){response(true);});$.lazy("noop-error",function(element,response){response(false);});})(window.jQuery||window.Zepto);;(function($){var srcAttr="data-src",srcsetAttr="data-srcset",mediaAttr="data-media",sizesAttr="data-sizes",typeAttr="data-type";$.lazy(["pic","picture"],["picture"],function(element,response){var elementTagName=element[0].tagName.toLowerCase();if(elementTagName==="picture"){var sources=element.find(srcAttr),image=element.find("data-img"),imageBase=this.config("imageBase")||"";if(sources.length){sources.each(function(){renameElementTag($(this),"source",imageBase);});if(image.length===1){image=renameElementTag(image,"img",imageBase);image.on("load",function(){response(true);}).on("error",function(){response(false);});image.attr("src",image.attr(srcAttr));if(this.config("removeAttribute")){image.removeAttr(srcAttr);}}
else if(element.attr(srcAttr)){createImageObject(element,imageBase+element.attr(srcAttr),response);if(this.config("removeAttribute"))
element.removeAttr(srcAttr);}
else{response(false);}}
else if(element.attr(srcsetAttr)){$("<source>").attr({media:element.attr(mediaAttr),sizes:element.attr(sizesAttr),type:element.attr(typeAttr),srcset:getCorrectedSrcSet(element.attr(srcsetAttr),imageBase)}).appendTo(element);createImageObject(element,imageBase+element.attr(srcAttr),response);if(this.config("removeAttribute")){element.removeAttr(srcAttr+" "+srcsetAttr+" "+mediaAttr+" "+sizesAttr+" "+typeAttr);}}
else{response(false);}}
else{response(false);}});function renameElementTag(element,toType,imageBase){var attributes=element.prop("attributes"),target=$("<"+toType+">");$.each(attributes,function(index,attribute){if(attribute.name==="srcset"||attribute.name===srcAttr){attribute.value=getCorrectedSrcSet(attribute.value,imageBase);}
target.attr(attribute.name,attribute.value);});element.replaceWith(target);return target;}
function createImageObject(parent,src,response){var imageObj=$("<img>").one("load",function(){response(true);}).one("error",function(){response(false);}).appendTo(parent).attr("src",src);imageObj.complete&&imageObj.load();}
function getCorrectedSrcSet(srcset,imageBase){if(imageBase){var entries=srcset.split(",");srcset="";for(var i=0,l=entries.length;i<l;i++){srcset+=imageBase+entries[i].trim()+(i!==l-1?",":"");}}
return srcset;}})(window.jQuery||window.Zepto);;(function($){$.lazy(["js","javascript","script"],"script",function(element,response){if(element[0].tagName.toLowerCase()=="script"){element.attr("src",element.attr("data-src"));if(this.config("removeAttribute"))
element.removeAttr("data-src");}
else{response(false);}});})(window.jQuery||window.Zepto);;(function($){$.lazy("vimeo",function(element,response){if(element[0].tagName.toLowerCase()==="iframe"){element.attr("src","https://player.vimeo.com/video/"+element.attr("data-src"));if(this.config("removeAttribute"))
element.removeAttr("data-src");}
else{response(false);}});})(window.jQuery||window.Zepto);;(function($){$.lazy(["yt","youtube"],function(element,response){if(element[0].tagName.toLowerCase()==="iframe"){element.attr("src","https://www.youtube.com/embed/"+element.attr("data-src")+"?rel=0&amp;showinfo=0");if(this.config("removeAttribute"))
element.removeAttr("data-src");}
else{response(false);}});})(window.jQuery||window.Zepto);